<template>
  <div class="p-3 bg-gray-relative-100 mh-vh-100-nav">
    <SubHeader title="매니저관리"></SubHeader>
    <div class="mx-3 bg-body rounded-4 shadow-400">
            <div class="my-2 pb-2">
                <ul class="mx-3 last-border-0" v-if="list&&list.length>0">
                    <li class="px-2 py-3 flex-between-center border-bottom border-gray-relative-200" v-for="(item,index) in list" :key="index">
                        <div class="flex-between-center">
                            <div class="d-flex flex-column fs-px-13">
                                <small class="text-gray-relative">{{item.email}}</small>
                                <small class="text-gray-relative-500">{{item.name}}</small>
                            </div>
                        </div>
                        <div class="flex-between-center">
                            <div class="d-flex flex-column border-end pe-3">
                                <small class="text-end text-success">{{ CheckAuth(item.auth) }}</small>
                            </div>
                            <span class="badge w-px-30 h-px-30 rounded-3 fw-normal fs-px-12 px-2 ms-3 d-flex flex-column align-items-center justify-content-center shadow-100 border" @click="ResetPassword(item)">
                                <i class="far fa-envelope text-muted"></i>
                            </span>
                            <span class="badge w-px-30 h-px-30 rounded-3 fw-normal fs-px-12 px-2 ms-3 d-flex flex-column align-items-center justify-content-center shadow-100 border" @click="EditManager(item)">
                                <i class="fal fa-edit text-muted"></i>
                            </span>
                            <span class="badge w-px-30 h-px-30 rounded-3 fw-normal fs-px-12 px-2 ms-3 d-flex flex-column align-items-center justify-content-center shadow-100 border" @click="RemoveConfirmManager(item)">
                                <i class="fal fa-trash text-muted"></i>
                            </span>
                        </div>
                    </li>
                </ul>
                <div class="p-3 py-6 text-center text-gray-relative-500" v-else>
                    <i class="fas fa-users fa-3x text-gray-relative-400 d-block my-3"></i>
                    <p class="my-2 fs-px-13">매니저 목록이 없어요.<br>매니저를 추가해주세요.</p>
                </div>
            </div>
        </div>
    
    <div class="mx-3 rounded-3 bg-body shadow-200">
      <h6 class="fw-bold p-3">매니저 정보</h6>
          <hr class="m-0 border-gray-relative-400">

          <div class="p-3">
              <!-- 고객이름 -->
              <div class="form-group">
                  <label class="small text-gray-relative-600">이메일</label>
                  <input class="form-control thick border-gray-relative-300 bg-body text-body fw-bold fs-6" type="text" placeholder="매니저 이메일" v-model="email">
              </div>
              <div class="form-group">
                  <label class="small text-gray-relative-600">이름</label>
                  <input class="form-control thick border-gray-relative-300 bg-body text-body fw-bold fs-6" type="text" placeholder="매니저 이름" v-model="name">
              </div>
              <div class="form-group">
                  <label class="small text-gray-relative-600">권한</label>
                  <select v-model="auth" class="form-control">
                    <option value="">권한을 선택해주세요.</option>
                    <option value="M">관리자</option>
                    <option value="C">정산</option>
                    <option value="R">예약</option>
                  </select>
              </div>
              <div class="form-group">
                  <label class="small text-gray-relative-600">부서</label>
                  <input class="form-control thick border-gray-relative-300 bg-body text-body fw-bold fs-6" type="text" placeholder="매니저 부서" v-model="department">
              </div>
              <div class="form-group">
                  <label class="small text-gray-relative-600">직급</label>
                  <input class="form-control thick border-gray-relative-300 bg-body text-body fw-bold fs-6" type="text" placeholder="매니저 이름" v-model="rank">
              </div>
              <div class="form-group">
                  <label class="small text-gray-relative-600">연락망</label>
                  <input class="form-control thick border-gray-relative-300 bg-body text-body fw-bold fs-6" type="text" placeholder="매니저 연락망" v-model="tel">
              </div>
              <div class="form-group">
                  <label class="small text-gray-relative-600">FAX</label>
                  <input class="form-control thick border-gray-relative-300 bg-body text-body fw-bold fs-6" type="text" placeholder="매니저 FAX" v-model="fax">
              </div>
              <div class="form-group">
                  <label class="small text-gray-relative-600">긴급 연락망</label>
                  <input class="form-control thick border-gray-relative-300 bg-body text-body fw-bold fs-6" type="text" placeholder="매니저 긴급연락망" v-model="e_tel">
              </div>
          </div>

      </div>

      <div @click="ManagerAdd()" class="rounded-3 btn thick btn-main w-100 mt-4" v-if="mode =='N'">매니저추가</div>
      <div class="border-top p-3 border-gray-relative-200" v-if="mode=='E'">
          <ul class="d-flex">
              <li class="rounded-3 p-2 text-center bg-gray-relative-200 flex-grow-1 me-1" @click="ResetInfo()">취소</li>
              <li class="rounded-3 p-2 text-center bg-main flex-grow-1 text-white"  @click="ManagerEdit()">정보수정</li>
          </ul>
      </div>
      <Loading v-if="loading"/>
  </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue'
import Loading from '@/components/common/Loading.vue'
const CryptoJS = require("crypto-js");

export default {
  components: {
      SubHeader,Loading
  },
  data(){
      return{
        loading : false,
        mode : 'N',
        email : '',
        name : '',
        department : '',
        rank : '',
        tel : '',
        fax : '',
        e_tel : '',
        auth:"",
        list : [],
        t_code : '',
      }
  },
  mounted(){
    this.GetManagerList();
  },
  methods:{
    RemoveConfirmManager(item){
      this.t_code = item.code;
      this.$confirm(`관리자를 삭제하시겠습니까?`).then((result)=>{
        if( result.isOk){
          this.RemoveManager()
        }
      })
    },
    CheckAuth(auth){
      if(auth =='M'){
        return "관리자";
      }else if(auth =='R'){
        return "예약";
      }else if(auth =='C'){
        return "정산";
      }
    },
    ResetInfo(){
      this.mode = 'N';
      this.email = '';
      this.name = '';
      this.department = '';
      this.rank = '';
      this.tel = '';
      this.fax = '';
      this.auth = '';
      this.e_tel = '';

    },
    ResetPassword(item){
      this.$confirm(`'${item.email} (${item.name})관리자'<br>비밀번호를 초기화하시겠습니까?`).then((result)=>{
        if( result.isOk ){
          this.t_code = item.code;
          this.ResetPasswordConfirm();
        }
      })
    },
    EditManager(item){
      
      this.email = item.email;
      this.name = item.name;
      this.department = item.department;
      this.rank = item.rank;
      this.tel = item.tel;
      this.fax = item.fax;
      this.auth = item.auth;
      this.e_tel = item.e_tel;
      
      this.t_code = item.code;
      this.mode = 'E';


    },
    ResetPasswordConfirm(){
      const code = this.t_code;
      const body = {code};
      const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
      this.loading = true;
      this.$http.post('/shop/manager/ResetPassword',{req}).then(
        (res) =>  { 
          if(res.status == 200){
            this.loading = false;
            if(res.data.code =="200"){
              this.$alert("관리자 이메일로 초기화비밀번호를 전송하였습니다.").then(
                  ()=>{
                    this.ResetInfo();
                  }
              )

            }else if(res.data.code=='9999'){
              this.$store.dispatch('SETLOGOUT').then(
                  ()=>{
                      window.location.href="/signin";
                  }
              )
            }
          }
        }
      );
    },
    RemoveManager(){
      
      const code = this.t_code;
      const body = {code};
      const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
      this.loading = true;
      this.$http.post('/shop/manager/RemoveManager',{req}).then(
        (res) =>  { 
          if(res.status == 200){
            this.loading = false;
            if(res.data.code =="200"){
              this.$alert("관리자를 삭제하였습니다.").then(
                  ()=>{
                    this.ResetInfo()
                    this.GetManagerList()
                  }
              )

            }else if(res.data.code=='9999'){
              this.$store.dispatch('SETLOGOUT').then(
                  ()=>{
                      window.location.href="/signin";
                  }
              )
            }
          }
        }
      );
    },
    ManagerEdit(){
      const code = this.t_code;
      const email = this.email;
      const name = this.name;
      const auth = this.auth;
      const department = this.department;
      const rank = this.rank;
      const tel = this.tel;
      const fax = this.fax;
      const e_tel = this.e_tel;
      if(email ==''){
        this.$alert("관리자 이메일을 입력해주세요.")
        return false
      }

      const body = {code,email,name,auth,department,rank,tel,fax,e_tel};
      const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
      this.loading = true;
      this.$http.post('/shop/manager/EditManager',{req}).then(
        (res) =>  { 
          if(res.status == 200){
            this.loading = false;
            if(res.data.code =="200"){
              this.$alert("관리자 정보를 수정하였습니다.").then(
                  ()=>{
                    this.ResetInfo()
                    this.GetManagerList();
                  }
              )

            }else if(res.data.code=='9999'){
              this.$store.dispatch('SETLOGOUT').then(
                  ()=>{
                      window.location.href="/signin";
                  }
              )
            }
          }
        }
      );
    },
    ManagerAdd(){
      
      const email = this.email;
      const name = this.name;
      const department = this.department;
      const rank = this.rank;
      const tel = this.tel;
      const fax = this.fax;
      const e_tel = this.e_tel;
      const auth = this.auth;

      if(email ==''){
        this.$alert("이메일 정보를 확인해주세요.")
        return false
      }

      
      const body = {email,name,department,rank,tel,fax,e_tel,auth};
      const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
      this.loading = true;
      this.$http.post('/shop/manager/ManagerAdd',{req}).then(
        (res) =>  { 
          if(res.status == 200){
            this.loading = false;
            if(res.data.code =="200"){
              this.$alert("관리자를 추가하였습니다.").then(
                  ()=>{
                    this.ResetInfo()
                      this.GetManagerList()
                  }
              )

            }else if(res.data.code=='9999'){
              this.$store.dispatch('SETLOGOUT').then(
                  ()=>{
                      window.location.href="/signin";
                  }
              )
            }
          }
        }
      );
    },
    GetManagerList(){
      
      const body = {};
      const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
      this.$http.post('/shop/manager/GetManagerList',{req}).then(
        (res) =>  { 
          if(res.status == 200){
            if(res.data.code =="200"){
              const e_body = res.data.body;
              const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
              const d_res = bytes.toString(CryptoJS.enc.Utf8);
              const body = JSON.parse(d_res)
              this.list = body.list;
            }else if(res.data.code=='9999'){
              this.$store.dispatch('SETLOGOUT').then(
                  ()=>{
                      window.location.href="/signin";
                  }
              )
            }
          }
        }
      );
    }   
  }
}
</script>

<style>

</style>