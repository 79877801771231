<template>
    <div id="loading" class="text-white">
        <i class="far fa-spinner fa-spin"></i>
        <p class="my-2">Loading...</p>
    </div>
</template>
  
  <script>
  export default {
  
  }
  </script>
  
<style lang="scss" scoped>
#loading{
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: #22212171;
    position: fixed;
    z-index: 999;
    backdrop-filter: blur(1px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    i.fa-spinner{
        font-size: 2rem;
    }
}
</style>
  